<template>
  <div>
    <BookingAgenda />
  </div>
</template>

<script>
import BookingAgenda from "@/components/portal/BookingAgenda";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    BookingAgenda,
  },
  metaInfo: {
    title: "Mijn Agenda | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
